import { useApi } from '~/composables/api/useApi'
import pkg from '../../package.json'

export function useCollections() {
  const { fetchWithAuth } = useApi()
  const RESSOURCE = '/collections'

  // GET Requests

  interface GetCollections {
    limit?: number
    index?: number
    sortColumn?: string
    sortDirection?: string
    filter?: string
    offset?: number
  }

  async function fetchCollections(providedOptions: GetCollections) {
    const defaultOptions: GetCollections = {
      sortColumn: 'colId',
      sortDirection: 'desc',
    }

    return await fetchWithAuth<CollectionList>(RESSOURCE, 'GET', {
      providedOptions: {
        nValues: providedOptions.limit,
        index: providedOptions.offset,
        sortColumn: providedOptions.sortColumn || defaultOptions.sortColumn,
        sortDirection:
          providedOptions.sortDirection || defaultOptions.sortDirection,
        filter: providedOptions.filter,
      },
    })
  }

  interface GetDocuments {
    collId?: number
    nValues?: number
    index?: number
    isDeleted?: boolean
    sortColumn?: string
    sortDirection?: string
  }

  async function fetchDocuments(providedOptions: GetDocuments) {
    const defaultOptions: GetDocuments = {
      isDeleted: false,
    }

    return await fetchWithAuth<Array<Document>>(
      `${RESSOURCE}/${providedOptions.collId}/list`,
      'GET',
      {
        providedOptions,
        defaultOptions,
      }
    )
  }

  async function fetchCollectionMeta(providedOptions: { collId: number }) {
    return await fetchWithAuth<CollectionMetadata>(
      `${RESSOURCE}/${providedOptions.collId}/metadata`,
      'GET',
      { providedOptions }
    )
  }

  async function fetchCollectionStats(providedOptions: { collId: number }) {
    return await fetchWithAuth<CollectionMetadata>(
      `${RESSOURCE}/${providedOptions.collId}/stats`,
      'GET',
      {}
    )
  }

  async function fetchUsersForCollection(providedOptions: { collId: number }) {
    return await fetchWithAuth<CollectionMetadata>(
      `${RESSOURCE}/${providedOptions.collId}/userlist`,
      'GET',
      { providedOptions }
    )
  }

  async function fetchTagDef(providedOptions: { collId: number }) {
    return await fetchWithAuth<CollectionMetadata>(
      `${RESSOURCE}/${providedOptions.collId}/listTagDefs`,
      'GET',
      {}
    )
  }

  async function fetchCollectionsForUser(providedOptions: {
    offset: number
    limit: number
    userId: number
  }) {
    const defaultOptions = {
      offset: 0,
      limit: -1,
    }
    return await fetchWithAuth<CollectionList>(`${RESSOURCE}`, 'GET', {
      providedOptions: {
        index: providedOptions.offset,
        nValues: providedOptions.limit,
        userid: providedOptions.userId,
      },
      defaultOptions,
    })
  }

  async function fetchCollectionsOfCollection(providedOptions: {
    collId: number
    docId: number
  }) {
    return await fetchWithAuth<CollectionList>(
      `${RESSOURCE}/${providedOptions.collId}/${providedOptions.docId}/collections`,
      'GET',
      { providedOptions }
    )
  }

  async function fetchDocumentsStats(providedOptions: {
    collId: number
    docId: number
  }) {
    return await fetchWithAuth<CollectionList>(
      `${RESSOURCE}/${providedOptions.collId}/${providedOptions.docId}/stats`,
      'GET',
      {}
    )
  }

  async function fetchDocumentsMeta(providedOptions: {
    collId: number
    docId: number
    limit?: number
  }) {
    return await fetchWithAuth<CollectionList>(
      `${RESSOURCE}/${providedOptions.collId}/${providedOptions.docId}/metadata`,
      'GET',
      { providedOptions: { nrOfTranscripts: providedOptions.limit } }
    )
  }

  async function filterCollections(providedOptions: {
    offset?: number
    limit?: number
    filter?: any
  }) {
    return await fetchWithAuth<CollectionList>(RESSOURCE, 'GET', {
      providedOptions,
    })
  }

  async function fetchCollectionCount(providedOptions: { filter?: number }) {
    return await fetchWithAuth<number>(`${RESSOURCE}/count`, 'GET', {
      providedOptions,
    })
  }

  async function fetchCollectionStorage(providedOptions: { collId: number }) {
    return await fetchWithAuth(
      `${RESSOURCE}/${providedOptions.collId}/details`,
      'GET',
      {
        providedOptions,
      }
    )
  }

  interface FindDocument {
    limit?: number
    offset?: number
    title?: string
    id?: number
    collId?: number
  }

  async function findDocuments(providedOptions: FindDocument) {
    return await fetchWithAuth(`${RESSOURCE}/findDocuments`, 'GET', {
      providedOptions: {
        nValues: providedOptions.limit,
        index: providedOptions.offset,
        title: providedOptions.title,
        id: providedOptions.id,
        collId: providedOptions.collId,
        exactMatch: false,
      },
    })
  }

  async function fetchPageMeta(providedOptions: {
    collId: number
    docId: number
    pageNr: number
  }) {
    return await fetchWithAuth(
      `${RESSOURCE}/${providedOptions.collId}/${providedOptions.docId}/${providedOptions.pageNr}/curr`,
      'GET',
      {}
    )
  }

  async function fetchPageInfo(providedOptions: {
    collId: number
    docId: number
    pageNr: number
  }) {
    const data = await fetchWithAuth(
      `${RESSOURCE}/${providedOptions.collId}/${providedOptions.docId}/${providedOptions.pageNr}`,
      'GET'
    )
    return data
  }

  interface GetPages {
    collId?: number
    docId?: number
    nrOfTranscripts?: number
    status?: string
  }

  async function fetchPages(providedOptions: GetPages) {
    const defaultOptions: GetPages = {
      nrOfTranscripts: 1,
    }
    return await fetchWithAuth(
      `${RESSOURCE}/${providedOptions.collId}/${providedOptions.docId}/fulldoc`,
      'GET',
      { providedOptions }
    )
  }

  async function fetchPagesString(providedOptions: {
    collId: number
    docId: number
    pageString?: string
  }) {
    return await fetchWithAuth(
      `${RESSOURCE}/${providedOptions.collId}/${providedOptions.docId}/pages`,
      'GET',
      {
        providedOptions: {
          pages: providedOptions.pageString,
        },
      }
    )
  }

  async function fetchCreditsCollection(providedOptions: { collId: number }) {
    return await fetchWithAuth(
      `${RESSOURCE}/${providedOptions.collId}/credits`,
      'GET',
      {}
    )
  }
  // POST Requests

  async function updateCollectionMeta(providedOptions: {
    collId: number
    metadata: any
  }) {
    return await fetchWithAuth(
      `${RESSOURCE}/${providedOptions.collId}/metadata`,
      'POST',
      { data: providedOptions.metadata }
    )
  }

  async function duplicateDoc(providedOptions: {
    collId: number
    copyObj: any
  }) {
    const data = JSON.stringify(providedOptions.copyObj)
    return await fetchWithAuth(
      `${RESSOURCE}/${providedOptions.collId}/duplicate`,
      'POST',
      { data }
    )
  }

  async function uploadAddToDocument(providedOptions: {
    collId: number
    docId: number
    pageNr: number
    data: FormData
  }) {
    return await fetchWithAuth(
      `${RESSOURCE}/${providedOptions.collId}/${providedOptions.docId}/${providedOptions.pageNr}/add`,
      'POST',
      { data: providedOptions.data }
    )
  }

  async function updateDocumentsMeta(providedOptions: {
    collId: number
    docId: number
    metadata: any
  }) {
    return await fetchWithAuth(
      `${RESSOURCE}/${providedOptions.collId}/${providedOptions.docId}/metadata`,
      'POST',
      { data: providedOptions.metadata }
    )
  }

  async function uploadPDFData(providedOptions: {
    collId: number
    data: FormData
  }) {
    return await fetchWithAuth(
      `${RESSOURCE}/${providedOptions.collId}/createDocFromPdf`,
      'POST',
      {
        data: providedOptions.data,
      }
    )
  }

  async function saveTagDef(providedOptions: { collId: number; def: any }) {
    const data = JSON.stringify(providedOptions.def)
    return await fetchWithAuth(
      `${RESSOURCE}/${providedOptions.collId}/tagDefs`,
      'POST',
      { data }
    )
  }

  interface CollectionUser {
    collId: number
    userId: number
    role: 'Owner' | 'Editor' | 'Transcriber' | 'ManualTranscriber' | 'Reader'
    data: any
  }

  async function addOrModifyUserInCollection(providedOptions: CollectionUser) {
    return await fetchWithAuth(
      `${RESSOURCE}/${providedOptions.collId}/addOrModifyUserInCollection`,
      'POST',
      {
        data: providedOptions.data,
        providedOptions: {
          userid: providedOptions.userId,
          role: providedOptions.role,
        },
      }
    )
  }

  async function updatePageStatus(providedOptions: {
    collId: number
    id?: number
    page?: any
    key?: any
    status?: any
  }) {
    return await fetchWithAuth(
      `${RESSOURCE}/${providedOptions.collId}/${providedOptions.id}/${providedOptions.page}/${providedOptions.key}/status`,
      'POST',
      {
        providedOptions: {
          status: providedOptions.status,
        },
      }
    )
  }

  async function removeUserCollection(providedOptions: {
    collId: number
    userId?: number
  }) {
    return await fetchWithAuth(
      `${RESSOURCE}/${providedOptions.collId}/removeUserFromCollection`,
      'POST',
      {
        providedOptions: {
          userid: providedOptions.userId,
        },
      }
    )
  }

  async function removeDocumentCollection(providedOptions: {
    collId: number
    docId?: number
  }) {
    return await fetchWithAuth(
      `${RESSOURCE}/${providedOptions.collId}/removeDocFromCollection`,
      'POST',
      { providedOptions: { id: providedOptions.docId } }
    )
  }

  async function addDocumentCollection(providedOptions: {
    collId: number
    docId?: number
  }) {
    return await fetchWithAuth(
      `${RESSOURCE}/${providedOptions.collId}/addDocToCollection`,
      'POST',
      { providedOptions: { id: providedOptions.docId } }
    )
  }

  async function addCreditsCollection(providedOptions: {
    collId: number
    packageId: number
  }) {
    return await fetchWithAuth(
      `${RESSOURCE}/${providedOptions.collId}/credits/${providedOptions.packageId}`,
      'POST',
      {}
    )
  }

  async function movePage(providedOptions: {
    collId: number
    docId: number
    pageNr: number
    moveTo: number
  }) {
    return await fetchWithAuth(
      `${RESSOURCE}/${providedOptions.collId}/${providedOptions.docId}/${providedOptions.pageNr}`,
      'POST',
      { providedOptions: { moveTo: providedOptions.moveTo } }
    )
  }

  async function moveDoc(providedOptions: {
    collId: number
    docId: number
    moveTo: boolean
  }) {
    return await fetchWithAuth(
      `${RESSOURCE}/${providedOptions.collId}/addDocToCollection`,
      'POST',
      {
        providedOptions: {
          id: providedOptions.docId,
          moveTo: providedOptions.moveTo,
        },
      }
    )
  }

  async function moveDocs(providedOptions: {
    collId: number
    docListObject: any
    moveTo: boolean
  }) {
    const data = JSON.stringify(providedOptions.docListObject)
    return await fetchWithAuth(
      `${RESSOURCE}/${providedOptions.collId}/addDocsToCollection`,
      'POST',
      {
        data,
        providedOptions: {
          moveTo: providedOptions.moveTo,
        },
      }
    )
  }

  async function createCollection(providedOptions: { collName: string }) {
    return await fetchWithAuth(`${RESSOURCE}/createCollection`, 'POST', {
      providedOptions,
    })
  }

  interface Page {
    pageId: number
  }

  interface Docs {
    doc: {
      docId: number
      pageList?: {
        pages: Array<Page>
      }
    }
  }

  interface ExportOptions {
    params?: {
      collId?: number
      docs?: Docs[]
      docId?: number
      pageString?: string
    }
    data: {
      commonPars?: {
        doExportDocMetadata?: boolean
        doWriteImages?: boolean
        doExportPageXml?: boolean
        doExportAltoXml?: boolean
        doWriteMets?: boolean
        doWritePdf?: boolean
        doWriteTei?: boolean
        doWriteDocx?: boolean
        doWriteTagsXlsx?: boolean
        doWriteTablesXlsx?: boolean
        doWriteTableSingleColumn?: boolean
        doWriteOneTableForDoc?: boolean
        doWriteWordsIntoTable?: boolean
        doWritePageMdXlsx?: boolean
        selectedTags?: boolean
        structureTagNames?: boolean
        exportTranscriptMetadata?: boolean
        tableColumn?: boolean
        doIncludeImagesInXlsx?: boolean
        doExportSingleTxtFiles?: boolean
        doWriteStructureInMets?: boolean
        doWriteOneTxt?: boolean
        doSplitTxt?: boolean
        doWriteTagsIob?: boolean
        writeTextOnWordLevel?: boolean
      }
      pdfPars?: {
        doPdfWithTags?: boolean
        doPdfWithTextPages?: boolean
        doPdfWithArticles?: boolean
        doPdfImagesOnly?: boolean
        doPdfImagesPlusText?: boolean
        doPdfA?: boolean
      }
      docxPars?: {
        doDocxWithTags?: boolean
        doDocxPreserveLineBreaks?: boolean
        doDocxForcePageBreaks?: boolean
        doDocxMarkUnclear?: boolean
        doDocxWriteFilenames?: boolean
        doDocxShowSuppliedTagWithBrackets?: boolean
        doDocxKeepAbbrevs?: boolean
        doDocxExpandAbbrevs?: boolean
        doDocxSubstituteAbbrevs?: boolean
      }
      altoPars?: {
        splitIntoWordsInAltoXml?: boolean
      }
      teiPars?: {
        useXslTransformation?: boolean
        useBoundingRects?: boolean
        useAbElements?: boolean
        tokenizeWhitespace?: boolean
        withoutTextline?: boolean
        combineContinuedTags?: boolean
        useRsTags?: boolean
        useWordCoordinates?: boolean
        exportPredefinedTagsAndAttsInTei?: boolean
        boundingBoxCoords?: boolean
        pbImageNameAsXmlId?: boolean
        regionZones?: boolean
        lineZones?: boolean
        wordZones?: boolean
        linebreakType?: string
      }
    }
  }

  async function serverDownload(providedOptions: ExportOptions) {
    return await fetchWithAuth(
      `${RESSOURCE}/${providedOptions.params?.collId}/${providedOptions.params?.docId}/export`,
      'POST',
      {
        data: providedOptions.data,
        providedOptions: {
          pages: providedOptions.params?.pageString,
        },
      }
    )
  }

  async function serverDownloadCollection(providedOptions: ExportOptions) {
    return await fetchWithAuth(
      `${RESSOURCE}/${providedOptions.params?.collId}/export`,
      'POST',
      {
        data: {
          commonPars: providedOptions.data.commonPars,
          pdfPars: providedOptions.data.pdfPars,
          docxPars: providedOptions.data.docxPars,
          altoPars: providedOptions.data.altoPars,
          teiPars: providedOptions.data.teiPars,
          docs: providedOptions.params?.docs,
        },
      }
    )
  }

  async function deleteVersion(options: {
    collId: number
    docId: number
    pageNr: number
    versionKey: string
  }) {
    return await fetchWithAuth(
      `${RESSOURCE}/${options.collId}/${options.docId}/${options.pageNr}/delete`,
      'POST',
      {
        providedOptions: {
          key: options.versionKey,
        },
      }
    )
  }

  interface SetXml {
    collId: string
    id: string
    page: string
    xml: string
    status: string
    parentTranscriptId: string
  }

  async function setPageContentXML(options: SetXml) {
    const config = useRuntimeConfig()
    const getToolName = () => {
      switch (config.public.ENVIRONMENT_NAME) {
        case 'alpha':
          return 'Alpha'
        case 'beta':
          return 'Beta'
        case 'staging':
          return 'Staging'
        case 'app-test':
          return 'Staging'
        case 'app':
          return 'App'
        default:
          return 'App'
      }
    }
    return await fetchWithAuth(
      `${RESSOURCE}/${options.collId}/${options.id}/${options.page}/text`,
      'POST',
      {
        data: options.xml,
        providedHeaders: { 'Content-Type': 'text/xml' },
        providedOptions: {
          status: options.status,
          toolName: getToolName() + '_' + pkg.version,
        },
      }
    )
  }

  // DELETE Requests

  async function deleteCollection(options: { collId: number }) {
    return await fetchWithAuth(`${RESSOURCE}/${options.collId}`, 'DELETE')
  }

  interface DeleteDocument {
    collId?: number
    docId?: number
    forEver?: boolean
  }

  async function deleteDocument(options: DeleteDocument) {
    return await fetchWithAuth(
      `${RESSOURCE}/${options.collId}/${options.docId}`,
      'DELETE',
      {
        providedOptions: {
          delete: options.forEver,
        },
      }
    )
  }

  async function deletePage(options: {
    collId: number
    docId: number
    pageNr: number
  }) {
    return await fetchWithAuth(
      `${RESSOURCE}/${options.collId}/${options.docId}/${options.pageNr}`,
      'DELETE'
    )
  }

  async function removeCreditsCollection(options: {
    collId: number
    packageId: number
  }) {
    return await fetchWithAuth(
      `${RESSOURCE}/${options.collId}/credits/${options.packageId}`,
      'DELETE'
    )
  }

  return {
    setPageContentXML,
    fetchCollections,
    fetchCollectionMeta,
    fetchCollectionsForUser,
    updateCollectionMeta,
    uploadAddToDocument,
    uploadPDFData,
    fetchCollectionsOfCollection,
    fetchCollectionCount,
    fetchCollectionStorage,
    fetchUsersForCollection,
    fetchTagDef,
    saveTagDef,
    addOrModifyUserInCollection,
    removeUserCollection,
    deleteDocument,
    deletePage,
    deleteVersion,
    removeDocumentCollection,
    addDocumentCollection,
    fetchDocuments,
    findDocuments,
    fetchCollectionStats,
    fetchDocumentsStats,
    fetchDocumentsMeta,
    updateDocumentsMeta,
    fetchPageMeta,
    fetchPages,
    fetchPagesString,
    fetchPageInfo,
    duplicateDoc,
    movePage,
    moveDoc,
    moveDocs,
    serverDownload,
    createCollection,
    fetchCreditsCollection,
    addCreditsCollection,
    removeCreditsCollection,
    deleteCollection,
    filterCollections,
    updatePageStatus,
    serverDownloadCollection,
  }
}
